import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';

import logo from '../assets/logo.png';
import './index.css';

const NavBar = () => {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setDropdownVisible(false);
    };

    return (
        <nav className="navbar">
            <Link to="/" className="logo">
                <img className="logo" src={logo}/>
            </Link>
            <div className="nav-links">
            {/* {isLoggedIn && ( */}
            <Link to="/" className="nav-item">
                Dashboard
            </Link>
            {/* )} */}
            {/* <Link to="/" className="nav-item">
                Home
            </Link> */}
            <Link to="/about" className="nav-item">
                About
            </Link>
            {/* <Link to="/contact" className="nav-item">
                Contact
            </Link> */}
            {/* {isLoggedIn ? (
                    <div className="user-profile" 
                         onMouseEnter={() => setDropdownVisible(true)}
                         onMouseLeave={() => setDropdownVisible(false)}>
                        U
                        {dropdownVisible && (
                            <div className="dropdown">
                                <div className="dropdown-item">
                                    <span className="icon-placeholder"></span> <span>Username</span>
                                </div>
                                <div to="/" className="dropdown-item" onClick={handleLogout}>
                                    <span className="icon-placeholder"></span><span>Log out</span>
                                </div>
                                <Link to="/settings" className="dropdown-item">
                                    <span className="icon-placeholder"></span> <span>Settings</span>
                                </Link>
                                <Link to="/device-list" className="dropdown-item">
                                    <span className="icon-placeholder"></span> <span>Device List</span>
                                </Link>
                            </div>
                        )}
                    </div>
                ) : (
                    <Link to="/auth" className="nav-item">
                        Log In
                    </Link>
                )} */}
            </div>
        </nav>
    );
};

export default NavBar;