export const MEMBERS = [
    { 
        name: 'Alex Jones', 
        position: 'Mechanical & Co-Electrical Lead', 
        description: 'Alex specializes in low-voltage electrical systems and is both an electrical co-lead and the mechanical lead on the ToxiTrack capstone project. He is responsible for both PCB design & bring-up as well as the design and fabrication of the mechanical enclosure. Alex has worked in electrical and electromechanical roles at startups such as ARC, ICSPI, and Swap Robotics.', 
        image: '/assets/jones.png' 
    },
    { 
        name: 'Yuvi Gill', 
        position: 'Integration & Co-Electrical Lead', 
        description: 'Yuvi specializes in electrical systems and system architecture design. He is an electrical co-lead and the integration lead on the ToxiTrack capstone project. He is responsible for the electrical architecture of the system; PCB design & bring-up. He is also aiding in firmware bring-up tasks such as writing BMS firmware, writing drivers and developing BLE mesh. Yuvi has worked at companies such as Telsa, HeadSpin, Bombardier, and eleven-x.', 
        image: '/assets/gill.png' 
    },
    { 
        name: 'Erfan Huq', 
        position: 'Software Lead', 
        description: 'Erfan specializes in web development software and is the software lead on the ToxiTrack capstone project. He is responsible for developing this website and aiding in firmware bring-up tasks such as writing drivers, MQTT, data logging, and internal tooling. Erfan has worked at Aurora Solar, Miovision, Ford, and the University of Waterloo in software development roles.', 
        image: '/assets/huq.png' 
    },
    { 
        name: 'Hewitt McGaughey', 
        position: 'Firmware Lead', 
        description: 'Hewitt specializes in firmware development and is the firmware lead on the ToxiTrack capstone project. He is responsible for the firmware bring-up of the PCBs, including driver development, BLE bring-up, MQTT forwarding, and edge-device integration. Hewitt has worked at companies such as Tesla, Parallel Systems, Splunk, onsemi, and eleven-x.', 
        image: '/assets/mcgaughey.png' 
    },
];
