export const getColor = (value) => {
    const gradientKeys = Object.keys(heatmapGradient).map(key => parseFloat(key)).sort((a, b) => a - b);

    for (let i = gradientKeys.length - 1; i >= 0; i--) {
        if (value >= gradientKeys[i]) {
            return heatmapGradient[gradientKeys[i].toString()];
        }
    }
    return heatmapGradient['1']; 
};

export const heatmapGradient = {
    0.2: 'blue',
    0.4: 'lime',
    0.6: 'yellow',
    0.8: 'orange',
    1.0: 'red'
};

export const createGradient = (minValue, maxValue) => {
    const range = maxValue - minValue;
    let gradient = {};
    let labels = {};

    // Calculate the step based on the number of colors in the gradient
    const step = range / (Object.keys(heatmapGradient).length - 1);

    // Iterate through the gradient keys in ascending order
    Object.keys(heatmapGradient).sort((a, b) => parseFloat(a) - parseFloat(b)).forEach((key, index) => {
        // Calculate the normalized intensity value (between 0 - 1)
        const intensity = parseFloat(key);
        // Assign the color to the corresponding intensity in the gradient
        gradient[intensity.toFixed(1)] = heatmapGradient[key];
        // Calculate the actual value corresponding to this intensity
        const valueForLabel = minValue + step * index;
        labels[intensity.toFixed(1)] = valueForLabel.toFixed(1);
    });

    const getLabelForValue = (normalizedValue) => {
        // Find the closest matching label for the given normalizedValue
        const closest = Object.keys(labels).reduce((prev, curr) =>
            (Math.abs(curr - normalizedValue) < Math.abs(prev - normalizedValue) ? curr : prev), "0");
        return labels[closest];
    };

    return { gradient, getLabelForValue };
};