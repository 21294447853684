import React from 'react';
import './index.css';

const CustomRadioButtons = ({ options, name, selectedValue, onChange }) => {
  return (
    <div className="form">
      {options.map((option, index) => (
        <React.Fragment key={option.value}>
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
          <input
            id={`${name}-${option.value}`}
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CustomRadioButtons;
