import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';

// data will be in the shape: [{
//     Timestamp,
//     value,
//     id,
//     latitude,
//     longitude
// }]

const HeatmapLayer = ({ data, gradient }) => {
    const map = useMap();
    const NORMALIZATION_FACTOR = 1;

    useEffect(() => {
        if (!map || !data || data.length === 0) return;

        const validData = data.filter(d => d.latitude !== null && d.longitude !== null && d.value !== null);

        const values = validData.map(d => d.value);
        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);

        const heatmapPoints = validData.map(d => {
            const normalizedValue = ((d.value - minValue) / (maxValue - minValue)) * NORMALIZATION_FACTOR;
            const pointValue = Math.max(normalizedValue, 0.1);
            return [d.latitude, d.longitude, pointValue]; // Latitude, Longitude, Intensity
        });

        const heatmapLayer = L.heatLayer(heatmapPoints, {
            radius: 25,
            blur: 10,
            maxZoom: 10,
            gradient: gradient
        }).addTo(map);

        return () => {
            if (map.hasLayer(heatmapLayer)) {
                map.removeLayer(heatmapLayer);
            }
        };
    }, [map, data, gradient]);

    return null;
};

export default HeatmapLayer;