import React from 'react';
import './index.css';

const CustomCheckbox = ({ checked, onChange, label, disabled = false }) => {
	return (
		<label className={`checkbox-container ${disabled ? 'disabled' : ''}`}>
			<p className='label'>
				{label}
			</p>
			<input
				type="checkbox"
				className="custom-checkbox"
				checked={checked}
				onChange={onChange}
			/>
			<span className="checkmark"></span>
		</label>
	);
};

export default CustomCheckbox;