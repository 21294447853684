import axios from 'axios';

export const getSensorData = async (sensorType, startDate, endDate) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-data`, {
            params: { sensorType, startDate, endDate }
        });
        console.log(response.data);
        return response;
    } catch (error) {
        console.error(error.response.data.message);
        return error;
    }
};
  
export const getSensorDataByID = async (sensorType, startDate, endDate, device_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-data-id`, {
            params: { sensorType, startDate, endDate, device_id }
        });
        return response;
    } catch (error) {
        console.error(error.response.data.message);
        return error.response.status;
    }
};
export const filterDataByType = (data, dataType, locations) => {
    // Assuming data is an array of objects with various data types including the dataType
    // locations is an array of objects with shape { latitude, longitude, gps_timestamp, id }

    return data.map(entry => {
        const closestLocation = locations.reduce((prev, curr) => {
            return (prev === null || Math.abs(curr.Timestamp - entry.Timestamp) < Math.abs(prev.Timestamp - entry.Timestamp)) ? curr : prev;
        }, null);

        if (closestLocation && entry[dataType] !== undefined) {
            return {
                Timestamp: entry.Timestamp,
                value: entry[dataType],
                id: entry.device_id,
                latitude: closestLocation.latitude,
                longitude: closestLocation.longitude
            };
        }

        return null;
    }).filter(entry => entry !== null);
};

export const formatTimeLabel = (startUnix, endUnix) => {
    const startDate = new Date(startUnix * 1000);
    const endDate = new Date(endUnix * 1000);

    startDate.setMinutes(Math.floor(startDate.getMinutes() / 10) * 10);
    startDate.setSeconds(0);

    endDate.setMinutes(Math.ceil(endDate.getMinutes() / 10) * 10);
    endDate.setSeconds(0);

    const formatOptions = { hour: '2-digit', minute: '2-digit' };
    let startLabel = startDate.toLocaleTimeString([], formatOptions);
    let endLabel = endDate.toLocaleTimeString([], formatOptions);

    if (startDate.getDate() !== endDate.getDate() || startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
        formatOptions.day = '2-digit';
        formatOptions.month = '2-digit';  
        startLabel = startDate.toLocaleDateString([], formatOptions); 
        endLabel = endDate.toLocaleDateString([], formatOptions); 
    }

    if (startDate.getFullYear() !== endDate.getFullYear()) {
        formatOptions.year = 'numeric';   
        startLabel = startDate.toLocaleDateString([], formatOptions); 
        endLabel = endDate.toLocaleDateString([], formatOptions); 
    }

    return {
        startLabel: { label: startLabel, style: { color: 'white'} },
        endLabel: { label: endLabel, style: { color: 'white' } }
    };
        
};

export const roundToNearestTenMinutes = (times) => {
    return times.map(time => {
        const date = new Date(time * 1000);
        const minutes = date.getMinutes();
        const roundedMinutes = Math.round(minutes / 10) * 10;
        date.setMinutes(roundedMinutes);
        date.setSeconds(0);
    
        return Math.floor(date.getTime() / 1000);
    });
};