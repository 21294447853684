import React, { useContext, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import Slider from 'rc-slider';
import L from 'leaflet';
import { DateRangePicker } from 'rsuite';
import 'leaflet/dist/leaflet.css';
import 'rc-slider/assets/index.css';
import 'rsuite/dist/rsuite.min.css';

import { AuthContext } from '../../contexts/authContext';
import HeatmapLayer from './heatmap-layer';
import { createGradient } from '../../utils/mapUtils';
import { getSensorDataByID, filterDataByType, formatTimeLabel } from '../../utils/dataUtils';
import CustomCheckbox from '../../base-components/checkbox/checkbox';
import CustomRadioButtons from '../../base-components/radio-buttons/custom-radio-buttons';
import Card from '../../base-components/card/card';
import { Legend } from './legend';

import './index.css';
import AlertsTable from '../../components/alert-table/alert-table';

const DefaultIcon = L.divIcon({
    className: 'custom-div-icon',
    html: "<div style='background-color:red; width: 10px; height: 10px; border-radius: 50%;'></div>",
    iconSize: [10, 10],
    iconAnchor: [5, 5]
});
  
L.Marker.prototype.options.icon = DefaultIcon;

const TIME_INTERVAL = 600; // 10 minutes
const LOOP_RATE = 1000; // 1s
const DAY_S = 86400;
const MINUTE_MS = 60000;
const WATERLOO_COORDS = [43.4643, -80.5204];

const Dashboard = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isLooping, setIsLooping] = useState(false);
    const loopRef = useRef(null);
    const [isTimelapseActive, setIsTimelapseActive] = useState(false);
    const [liveData, setLiveData] = useState(false);
    const [liveDataBlob, setLiveDataBlob] = useState(false);

    const [dateRange, setDateRange] = useState([1710701903, 1711712954]);
    const [selectedTimeRange, setSelectedTimeRange] = useState([1710701903, 1711712954]);
    const [dataTimeRange, setDataTimeRange] = useState([1710701903, 1711712954]);
    const [specificTime, setSpecificTime] = useState(null);
    const [timeRangeLabels, setTimeRangeLabels] = useState({});

    const [currentData, setCurrentData] = useState();
    const [displayData, setDisplayData] = useState();

    const [deviceId, setDeviceId] = useState(47);
    const [devicesAvailable, setDevicesAvailable] = useState([47, 125]);
    const [sensorType, setSensorType] = useState('gps');
    const [dataType, setDataType] = useState('gps');
    const [dataNotFound, setDataNotFound] = useState(false);

    const sensorTypesAvailable = [
        { label: 'GPS', value: 'gps', dataTypesAvailable: ['gps']},
        { label: 'BME688', value: 'bme688', dataTypesAvailable: ['temp', 'humidity']},
        { label: 'SCD41', value: 'scd41', dataTypesAvailable: ['temp', 'humidity', 'co2']},
        { label: 'SEN55', value: 'sen55', dataTypesAvailable: ['pm1p0', 'pm2p5', 'pm4p0', 'pm10p0', 'ambient_humidity', 'ambient_temperature', 'voc_index', 'nox_index']},
        { label: 'SGX-4OX', value: 'o2', dataTypesAvailable: ['o2_percent']},
        { label: 'SGX-4CO', value: 'co', dataTypesAvailable: ['co_ppm']},
    ];

    const handleDateRangeChange = (range) => {
        if (range && range.length === 2 && range[0] && range[1]) {
            const unixRange = range.map(date => Math.floor(date.getTime() / 1000));
            setDateRange(unixRange);
            setLiveData(false);
        } else {
            setDateRange([]);
        }
    };

    const calculateGradient = useCallback(() => {
        if (sensorType !== sensorTypesAvailable[0].value && displayData) {
            const values = displayData.map(item => item['value']);
            let minDataValue = Math.min(...values);
            let maxDataValue = Math.max(...values);
    
            // Round dataValue up/down to the nearest ten
            minDataValue = Math.floor(minDataValue / 10) * 10;
            maxDataValue = Math.ceil(maxDataValue / 10) * 10;
    
            return createGradient(minDataValue, maxDataValue);
        }
        return { gradient: null, getLabelForValue: () => '' };
    }, [sensorType, displayData]);
    
    const { gradient, getLabelForValue } = useMemo(() => calculateGradient(), [calculateGradient]);    

    const startLoop = () => {
        if (loopRef.current) {
            clearInterval(loopRef.current);
        }

        if (specificTime === null || specificTime < dateRange[0] || specificTime > dateRange[1]) {
            setSpecificTime(dateRange[0]);
        }

        loopRef.current = setInterval(() => {
            setSpecificTime(prevTime => {
                let temp = prevTime;
                if (!prevTime) {
                    temp = dateRange[0];
                }
                const nextTime = temp + TIME_INTERVAL;
                return nextTime > dateRange[1] ? dateRange[0] : nextTime;
            });
        }, LOOP_RATE);

    };

    const stopLoop = () => {
        if (loopRef.current) {
            clearInterval(loopRef.current);
            loopRef.current = null;
        }
    };

    const handleDataTypeChange = (newValue) => {
        setDataType(newValue);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let filteredData;

                // get gps data
                const gpsDataResponse = await getSensorDataByID('gps', selectedTimeRange[0], selectedTimeRange[1], deviceId);
                const gpsDataExists = gpsDataResponse && gpsDataResponse.data && gpsDataResponse.data.length > 0;
                if (gpsDataExists) {
                    setDataNotFound(false);
                } else {
                    setDataNotFound(true);
                }
                let locations = gpsDataExists ? gpsDataResponse.data.map(entry => ({
                    Timestamp: entry.Timestamp,
                    latitude: entry?.latitude,
                    longitude: entry?.longitude,
                    device_id: entry.device_id
                })).filter(entry => entry.device_id !== undefined) : [];
                if (sensorType === 'gps') {
                    filteredData = locations;
                } else {
                    const sensorDataResponse = await getSensorDataByID(sensorType, selectedTimeRange[0], selectedTimeRange[1], deviceId);
                    const sensorDataExists = sensorDataResponse && sensorDataResponse.data && sensorDataResponse.data.length > 0;
                    if (sensorDataExists) {
                        setDataNotFound(false);
                    } else {
                        setDataNotFound(true);
                    }
                    if (sensorDataExists) {
                        locations = locations.length > 0 ? 
                            locations :
                            [{
                                device_id: deviceId, 
                                latitude: WATERLOO_COORDS[0], 
                                longitude: WATERLOO_COORDS[1], 
                                Timestamp: Date().now / 1000
                            }];
                        filteredData = filterDataByType(sensorDataResponse.data, dataType, locations);
                    } else {
                        filteredData = []
                    }
                }
                // filteredData will be in the shape: {
                //     Timestamp,
                //     value, // wont exist for gps data
                //     id,
                //     latitude,
                //     longitude
                // }

                if (filteredData.length > 0) {
                    setCurrentData(filteredData);
                    setDisplayData(filteredData);
                    const timestamps = filteredData.map(entry => entry.Timestamp);
                    const newTimeRange = [Math.min(...timestamps), Math.max(...timestamps)];
                    setDataTimeRange(newTimeRange);
                    setSelectedTimeRange(newTimeRange);

                    // Set time range labels for the slider
                    const { startLabel, endLabel } = formatTimeLabel(dateRange[0], dateRange[1]);
                    setTimeRangeLabels({
                        [dateRange[0]]: startLabel,
                        [dateRange[1]]: endLabel,
                    });
                } else {
                    setCurrentData([]);
                    setDisplayData([]);
                }    
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        setIsLoading(true);
        fetchData();
    }, [deviceId, sensorType, dataType]);

    useEffect(() => {
        if (currentData && selectedTimeRange.length === 2) {
            const latestTime = currentData.reduce((latest, item) => Math.max(latest, item.Timestamp), 0);
            if (!liveData || selectedTimeRange[1] < latestTime) {
                if (liveData) {
                    setLiveData(false);
                }
                const filteredData = currentData.filter(entry => {
                    const timestamp = entry.Timestamp;
                    return timestamp >= selectedTimeRange[0] && timestamp <= selectedTimeRange[1];
                });
        
                setDisplayData(filteredData);
            }
        }    
    }, [selectedTimeRange, currentData]);

    useEffect(() => {
        if (isTimelapseActive && specificTime !== null && currentData) {
            const oneHour = 3600;
            const startTime = specificTime - oneHour;
            const endTime = specificTime + oneHour;

            const filteredData = currentData.filter(entry => {
                const timestamp = entry.Timestamp;
                // Check if timestamp falls within the 1-hour range around specificTime
                return timestamp >= startTime && timestamp <= endTime;
            });

            setDisplayData(filteredData);
        } else if (!isTimelapseActive) {
            setDisplayData(currentData);
        }
    }, [isTimelapseActive, specificTime, currentData]);

    useEffect(() => {
        if (isLooping) {
            startLoop();
        } else {
            stopLoop();
        }

        return () => {
            if (loopRef.current) clearInterval(loopRef.current);
        };
    }, [isLooping, selectedTimeRange]);
    
    // useEffect(() => {
    //     let intervalId;
    //     const fetchLatestData = async () => {
    //         try {
    //             let newFilteredData;
    //             let startTime, endTime;
    //             let latestTimeAvailable = 0;
    //             if (currentData && currentData.length > 0) {
    //                 latestTimeAvailable = currentData.reduce((latest, item) => Math.max(latest, item.Timestamp), 0);
    //                 startTime = latestTimeAvailable + 1;
    //             } else {
    //                 startTime = Math.min([...dateRange, ...selectedTimeRange]);
    //             }
    //             endTime = Math.floor(Date.now() / 1000);
    //             if (startTime) {
    //                 const gpsDataResponse = await getSensorDataByID('gps', startTime, endTime, deviceId);
    //                 const gpsDataExists = gpsDataResponse && gpsDataResponse.data && gpsDataResponse.data.length > 0;
    //                 let locations = gpsDataExists ? gpsDataResponse.data.map(entry => ({
    //                     Timestamp: entry?.Timestamp,
    //                     latitude: entry?.latitude ? entry.latitude : WATERLOO_COORDS[0],
    //                     longitude: entry?.longitude ? entry.longitude : WATERLOO_COORDS[1],
    //                     device_id: entry?.device_id
    //                 })).filter(entry => entry.device_id !== undefined) : [];

    //                 if (sensorType === 'gps') {
    //                     newFilteredData = locations.length > 0 ? locations : [];
    //                 } else {
    //                     const sensorDataResponse = await getSensorDataByID(sensorType, startTime, endTime, deviceId);
    //                     const sensorDataExists = sensorDataResponse && sensorDataResponse.data && sensorDataResponse.data.length > 0;
    //                     if (sensorDataExists) {
    //                         if (locations.length < 1) {
    //                             if (currentData) {
    //                                 locations = [currentData[currentData.length - 1]];
    //                             } else {
    //                                 locations = [{
    //                                     latitude: WATERLOO_COORDS[0],
    //                                     longitude: WATERLOO_COORDS[1],
    //                                     Timestamp: endTime,
    //                                     device_id: deviceId
    //                                 }]
    //                             }
    //                         }
    //                         newFilteredData = filterDataByType(sensorDataResponse.data, dataType, locations);
    //                     } else {
    //                         newFilteredData = []
    //                     }
    //                 }

    //                 if (newFilteredData.length > 0) {
    //                     const timestamps = newFilteredData.map(entry => entry.Timestamp);
    //                     const newTimeRangeMax = Math.max(...timestamps);
    //                     setCurrentData([...currentData,  ...newFilteredData]);

    //                     newFilteredData = [...displayData, ...newFilteredData];
    //                     setDisplayData(newFilteredData);
    //                     setDataTimeRange([dataTimeRange[0], newTimeRangeMax]);
    //                     setSelectedTimeRange([selectedTimeRange[0], newTimeRangeMax]);

    //                     const { startLabel, endLabel } = formatTimeLabel(dateRange[0], dateRange[1]);
    //                     // Set time range labels for the slider                        
    //                     setTimeRangeLabels({
    //                         [dateRange[0]]: startLabel,
    //                         [dateRange[1]]: endLabel
    //                     });
    //                 }
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    //     if (liveData) {
    //         fetchLatestData();
    //         intervalId = setInterval(fetchLatestData, MINUTE_MS);
    //     } else if (intervalId) {
    //         clearInterval(intervalId);
    //     }

    //     return () => {
    //         if (intervalId) {
    //             clearInterval(intervalId);
    //         }
    //     };
    // }, [liveData]);

    useEffect(() => {
        if (dateRange.length === 2) {
            setSelectedTimeRange(dateRange);
            setDataTimeRange(dateRange);
            const { startLabel, endLabel } = formatTimeLabel(dateRange[0], dateRange[1]);
            setTimeRangeLabels({
                [dateRange[0]]: startLabel,
                [dateRange[1]]: endLabel,
            });
            setSpecificTime(null);
        } else {
            setSelectedTimeRange([]);
            setDataTimeRange([]);
            setTimeRangeLabels({});
            setSpecificTime(null);
        }
    }, [dateRange]);

    // const memoizedAlertsTable = useMemo(() => {
    //     return <AlertsTable />;
    // }, []);
    // if (!isLoggedIn) {
    //     return <Navigate to="/auth" replace />;
    // }

    return (
        <div>
            <h1 className='dashboard-title'>Dashboard</h1>
            <div className='sliders-container'>
                <div className='slider-wrapper active'>
                    <label>Time Range:</label>
                    <Slider
                        range
                        min={dateRange[0]}
                        max={dateRange[1]}
                        defaultValue={[dateRange[0], dateRange[1]]}
                        onChange={setSelectedTimeRange}
                        marks={timeRangeLabels}
                        trackStyle={{ backgroundColor: "#1D6640"}}
                        railStyle={{ backgroundColor: "#B7D1CA"}}
                        handleStyle={{ color: '#9ECF9D'}}
                    />
                </div>
                {
                    isTimelapseActive && (
                        <div className={`slider-wrapper ${isTimelapseActive ? 'active' : ''}`}>
                            <label>Timelapse:</label>
                            <Slider
                                min={dateRange[0]}
                                max={dateRange[1]}
                                value={specificTime || selectedTimeRange[0]}
                                onChange={value => setSpecificTime(value)}
                                step={TIME_INTERVAL}
                                marks={timeRangeLabels}
                                trackStyle={{ backgroundColor: "#1D6640"}}
                                railStyle={{ backgroundColor: "#B7D1CA"}}
                                handleStyle={{ color: '#9ECF9D'}}
                            />
                        </div>
                    )
                }
            </div>
            <div className="dashboard-container">
                <div className="controls-container">
                    {
                        dataNotFound && !isLoading && (
                            <div className='data-not-found-container'>
                                <span className='data-not-found'>
                                    Data not found for this configuration
                                </span>
                            </div>
                        )
                    }
                    <DateRangePicker
                        className='calendar' 
                        defaultValue={
                            dateRange.length === 2 ? [new Date(dateRange[0] * 1000), new Date(dateRange[1] * 1000)] : []
                        }
                        onChange={(e) => handleDateRangeChange(e)}
                        value={dateRange.map(unix => new Date(unix * 1000))}
                    />
                    <Card>
                        <div style={{display: 'flex', flexDirection: 'column', paddingTop: '5px', paddingBottom: '5px'}}>
                            <CustomCheckbox
                                label="Timelapse"
                                checked={isTimelapseActive}
                                onChange={() => {
                                    if (!liveData) {
                                        if (isTimelapseActive) {
                                            setIsLooping(false);
                                        }
                                        setIsTimelapseActive(!isTimelapseActive);
                                        setSpecificTime(null);
                                    }
                                }}
                                disabled={liveData}
                            />
                            <CustomCheckbox
                                label="Loop"
                                checked={isLooping}
                                onChange={() => {
                                    if (!liveData){
                                        setIsLooping(!isLooping);
                                        if (!isLooping) {
                                            setIsTimelapseActive(true);
                                        }
                                    }
                                }}
                                disabled={liveData}
                            />
                            <CustomCheckbox
                                label="Live Data"
                                checked={liveDataBlob}
                                onChange={() => {
                                    if (!liveDataBlob) {
                                        setIsLooping(false);
                                        setIsTimelapseActive(false);
                                    }
                                    setLiveDataBlob(!liveDataBlob)
                                }}
                            />
                        </div>
                        <div className="select-container">
                            <label className="label">
                                Select Device:
                            </label>
                            <select value={deviceId} onChange={e => {
                                setDeviceId(e.target.value);
                            }}>
                                {devicesAvailable.map(device => (
                                    <option key={device} value={device}>
                                        Device {device}
                                    </option>
                                ))}
                            </select> 
                        </div>
                        <div className="select-container">
                            <label className="label">
                                Select Sensor:
                            </label>
                            <select value={sensorType} onChange={e => {
                                setSensorType(e.target.value);
                                setDataType(sensorTypesAvailable.find(
                                    (sensor) => sensor.value === e.target.value).dataTypesAvailable[0]
                                );
                            }}>
                                {sensorTypesAvailable.map(type => (
                                    <option key={type.value} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <CustomRadioButtons
                            options={sensorTypesAvailable
                                .find((sensor) => sensor.value === sensorType)
                                .dataTypesAvailable.map((data) => ({
                                    label: data,
                                    value: data,
                                }))}
                            name="dataType"
                            selectedValue={dataType}
                            onChange={(e) => handleDataTypeChange(e)}
                        />
                    </Card>
                </div>             
                <div className="map-container">
                    <MapContainer center={WATERLOO_COORDS} zoom={5} style={{ height: "100%", width: "100%" }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {/* if gps is being accessed */}
                        {sensorType === sensorTypesAvailable[0].value && displayData && !isLoading && displayData.map((point, index) => {
                            if (point.latitude && point.longitude) {
                                return (
                                    <Marker position={[point.latitude, point.longitude]} key={`${point.device_id}-${index}`}>
                                        <Popup>
                                            Device ID: {point.device_id}<br />
                                            Latitude: {point.latitude.toFixed(2)}<br />
                                            Longitude: {point.longitude.toFixed(2)}<br />
                                        </Popup>
                                    </Marker>
                                )
                            } else {
                                return null;
                            }
                        })}
                        {sensorType === sensorTypesAvailable[0].value && displayData && !isLoading && (
                            Object.entries(displayData.reduce((acc, point) => {
                                if (point.latitude && point.longitude){
                                    if (!acc[point.device_id]) {
                                        acc[point.device_id] = [];
                                    }
                                    acc[point.device_id].push([point.latitude.toFixed(2), point.longitude.toFixed(2)]);
                                    return acc;

                                } else {
                                    return [];
                                }
                            }, {})).map(([deviceId, positions]) => (
                                <Polyline key={`polyline-${deviceId}`} positions={positions} color="red" />
                            ))
                        )}
                        {sensorType !== sensorTypesAvailable[0].value &&
                            <>
                                <HeatmapLayer data={displayData} dataField={dataType} gradient={gradient} />
                                <Legend data={displayData} dataType={dataType} gradient={gradient} getLabelForValue={getLabelForValue} />
                            </>
                        }
                    </MapContainer>
                </div>
            </div>
            {/* <div className="alerts-table-wrapper">
                <div className='alerts-table'>
                    {memoizedAlertsTable}
                </div>
            </div> */}
        </div>
        
    );
};

export default Dashboard;
