import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, About, Contact, Authorization, Dashboard, Settings, Devices } from './pages';
import NavBar from './base-components/navbar/navbar';
import Footer from './base-components/footer/footer';

import { AuthProvider } from './contexts/authContext';

import './index.css';

const App = () => {
    return (
		<AuthProvider>
			<Router>
				<div className="app-container">
					<NavBar />
					<div className='main-content'>
						<Routes>
							<Route path="/" element={<Dashboard />} />
							<Route path="/about" element={<About />} />
							{/* <Route path="/contact" element={<Contact />} /> */}
							{/* <Route path="/auth" element={<Authorization />} /> */}
							{/* <Route path="/dashboard" element={<Dashboard />} />	 */}
							{/* <Route path="/settings" element={<Settings />} /> */}
							{/* <Route path="/device-list" element={<Devices />} /> */}
						</Routes>
					</div>
					{/* <Footer /> */}
				</div>
			</Router>
		</AuthProvider>
    );
};

export default App;
