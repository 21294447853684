import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

// data will be in the shape: [{
//     Timestamp,
//     value,
//     id,
//     latitude,
//     longitude
// }]

export const Legend = ({ data, gradient, dataType, getLabelForValue }) => {
    const map = useMap();
    
    useEffect(() => {
        if (dataType === 'gps' || !gradient) {
            return;
        }
        const legend = L.control({ position: "bottomright" });
        legend.onAdd = function () {
            const div = L.DomUtil.create("div", "info legend");
            let labels = [];

            Object.entries(gradient).forEach(([normalizedValue, color]) => {
                const label = getLabelForValue(normalizedValue);
                labels.push(
                    `<i style="background:${color}"></i> ${label}`
                );
            });

            div.innerHTML = labels.join('<br>');
            return div;
        };        

        legend.addTo(map);
        return () => {
            legend.remove();
        };
    }, [map, gradient, dataType, data]);
    return null;
};
